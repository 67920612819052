<template>
  <div class="view">
    <div class="view-box">
      <div class="view-box-top">
        <img src="../assets/imgmain/line1.png" alt="">
      </div>
      <div class="view-box-center">
        <div class="text">
          <div class="node">
            <div class="num">1</div>
            <div class="val">
              血留边+血沉 全血高切粘都（200/S):5.54t高切刘阻（09.SI):38.70T
            </div>
          </div>
          <div class="node">
            <div class="num">1</div>
            <div class="val">
              血留边+血沉 全血高切粘都（200/S):5.54t高切刘阻（09.SI):38.70T
            </div>
          </div>
          <div class="node">
            <div class="num">1</div>
            <div class="val">
              血留边+血沉 全血高切粘都（200/S):5.54t高切刘阻（09.SI):38.70T
            </div>
          </div>
        </div>
        <img src="../assets/imgmain/line2.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.view {
  height: 100%;
  background: url("../assets/imgmain/bgview.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 46px;
  display: flex;
  justify-content: center;
  .view-box {
    position: relative;
    width: 698px;
    &-top {
      position: absolute;
      width: 100%;
      top: 0;
      z-index: 0;
      img {
        width: 100%;
      }
    }
    &-center {
      position: absolute;
      width: 100%;
      top: 16px;
      z-index: 10;
      .text {
        width: 648px;
        padding: 45px;
        margin: 0 auto;
        min-height: 200px;
        background-color: #fff;
        .node {
          display: flex;
          align-items: flex-start;
          padding: 18px 0;
          color: #00AF67;
          font-size: 16px;
          line-height: 1.4;
          border-bottom: 1px dashed #00AF67;
          .num {
            width: 54px;
            height: 54px;
            text-align: center;
            line-height: 54px;
            font-size: 16px;
            color: #fff;
            background-color: #00AF67;
            border-radius: 50%;
            flex-shrink: 0;
            margin-right: 18px;
            font-weight: 700;
          }
          .val {
            text-align: justify;
            word-break: break-all;
          }
        }
      }
      img {
        display: block;
        margin: 0 auto;
        width: 648px;
      }
    }
  }
}
</style>
